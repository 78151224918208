import React, { useState } from "react"
import HomeHeader from "./HomeHeader"
import "./HomeLayout.scss"

export enum Fade {
  In = "fadein",
  InUp = "fadein--up",
  InDown = "fadein--down",
  InLeft = "fadein--left",
  InRight = "fadein--right",
  Out = "fadeout",
  OutUp = "fadeout--up",
  OutDown = "fadeout--down",
  OutLeft = "fadeout--left",
  OutRight = "fadeout--right",
  None = "",
}

interface Props {
  page: "hero" | "login" | "404"
  fadeOut: boolean
  fadeOutAll: boolean
  children: JSX.Element | JSX.Element[]
}

const HomeLayout = ({ page, fadeOut, fadeOutAll, children }: Props) => {
  const [fadeOutOverride, setFadeOutOverride] = useState<boolean | undefined>(
    undefined
  )

  const trueFadeOut = fadeOutOverride ? fadeOutOverride : fadeOut

  const image =
    page === "hero"
      ? require("../../images/voting.svg")
      : page === "login"
      ? require("../../images/login.svg")
      : require("../../images/404.svg")

  const layoutAnimation: Fade = fadeOutAll ? Fade.Out : Fade.None

  const drawingAnimation: Fade =
    page === "hero"
      ? trueFadeOut
        ? Fade.OutUp
        : Fade.InUp
      : page === "login"
      ? trueFadeOut
        ? Fade.OutRight
        : Fade.InLeft
      : trueFadeOut
      ? Fade.Out
      : Fade.In

  const childAnimation: Fade =
    page === "hero"
      ? trueFadeOut
        ? Fade.OutDown
        : Fade.InDown
      : page === "login"
      ? trueFadeOut
        ? Fade.OutLeft
        : Fade.InRight
      : trueFadeOut
      ? Fade.Out
      : Fade.In

  return (
    <div className={`home-layout home-layout--${layoutAnimation}`}>
      <HomeHeader
        fadeIn={true}
        fadeOut={fadeOut}
        setFadeOut={setFadeOutOverride}
      />
      <div className="home-layout__content">
        <div
          className={`home-layout__content__child home-layout__content__child--${childAnimation}`}
        >
          {children}
        </div>
        <div
          className={`home-layout__drawing home-layout__drawing--${drawingAnimation}`}
        >
          <img src={image} />
        </div>
      </div>
    </div>
  )
}

export default HomeLayout
