import { navigate } from "gatsby"
import React, { useEffect } from "react"
import "./HomeHeader.scss"

interface Props {
  fadeIn?: boolean
  fadeOut: boolean
  setFadeOut: (value: boolean | undefined) => void
}

const HomeHeader = ({ fadeIn, fadeOut, setFadeOut }: Props) => {
  let headerClass = "home-header"
  if (fadeIn) headerClass += " home-header--fade"

  useEffect(() => {
    if (fadeOut === false || fadeOut) setFadeOut(undefined)
  }, [])

  return (
    <header className={headerClass}>
      <div className="home-header__content">
        <a
          onClick={() => {
            setFadeOut(true)
            setTimeout(() => navigate("/"), 250)
          }}
          className="home-header__content__title"
        >
          primavote.
        </a>
      </div>
    </header>
  )
}

export default HomeHeader
