import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import HomeLayout from "../components/home/HomeLayout"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { State } from "../context/State"
import HeroView from "../components/home/HeroView"

const Home = () => {
  const state = useContext<State>(GlobalStateContext)
  const [clickedGetStarted, setClickedGetStarted] = useState(false)
  const [fadeOut, setFadeOut] = useState(false)
  const [fadeOutAll, setFadeOutAll] = useState(false)

  const onGetStarted = () => {
    if (!clickedGetStarted) setClickedGetStarted(true)
    if (state.user === undefined) return

    setFadeOut(true)
    if (state.user) {
      setFadeOutAll(true)
      setTimeout(() => navigate("/dashboard"), 250)
    } else {
      setTimeout(() => navigate("/login"), 250)
    }
  }

  useEffect(() => {
    if (state.user !== undefined) {
      if (clickedGetStarted) {
        onGetStarted()
      }
    }
  }, [state])

  return (
    <HomeLayout fadeOutAll={fadeOutAll} fadeOut={fadeOut} page="hero">
      <HeroView onGetStarted={onGetStarted} loading={clickedGetStarted} />
    </HomeLayout>
  )
}

export default Home
