import React from "react"
import { Button } from "antd"
import "./Hero.scss"

interface HeroViewProps {
  onGetStarted: () => void
  loading: boolean
}

const HeroView = ({ onGetStarted, loading }: HeroViewProps) => (
  <div className="hero">
    <h1 className="hero__title">The voting tool you've been looking for.</h1>
    <p className="hero__subtitle">
      Simple and secure voting for groups of all kinds.
    </p>
    <Button
      className="hero__button"
      type="primary"
      size="large"
      shape="round"
      loading={loading}
      onClick={onGetStarted}
    >
      Get Started
    </Button>
  </div>
)

export default HeroView
